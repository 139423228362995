.login-form {
  max-width: 360px;
  margin: 10px auto;
}

.login-form label {
  display: block;
  margin: 0px auto;
}
.login-form span {
  display: block;
  margin-bottom: 6px;
}
.login-form input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}