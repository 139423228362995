.container {
  display: grid;
  grid-template-columns: 2fr 2fr;
  max-width: 1200px;
  margin: 0 auto;
}

.content {
  padding-right: 30px;
}

.sidebar {
  padding-left: 30px;
}
